import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useContext,
  useEffect
} from "react";
import {
  ClearRefinements,
  useCurrentRefinements
} from "react-instantsearch";

import GlobalContext from "../../../context/global-context";
import { ReactComponent as CloseSmallSVG } from '../../../images/close_small.svg';
import { generateKey } from "../../../utils/keys";
import { CustomSearchBox } from "../searchBox/searchBox";

import './refinements.scss';

type CustomRefinementProps = {
  toggle: Dispatch<SetStateAction<boolean>>;
  isOpened: boolean;
  setFilterCount: Dispatch<SetStateAction<number>>;
};

export const CustomRefinement : React.FC<CustomRefinementProps> = (props) => {
  const { items, refine } = useCurrentRefinements();
  const { toggle, isOpened, setFilterCount } = props;
  const { i18n } = useContext(GlobalContext);

  let filterCounter = 0;
  items.map((category) => {
    if (category.refinements)
      filterCounter += category.refinements.length;
    else
      filterCounter++;
  });

  useEffect(() => {
    setFilterCount(filterCounter);
  }, [filterCounter]);

  return (
    <>
      <CustomSearchBox
        className={`${items.length ? 'opened' : ''}`}
        toggle={toggle}
        isOpened={isOpened}
        counter={filterCounter}
      />

      {items.length !== 0 && (
        <div className="search-chips-container">
          <div className="search-chips">
            <span className="clear">
              <ClearRefinements
                translations={{
                  resetButtonText: i18n?.t('business_registry.clear')
                }}
              />
            </span>
            {items.map((item, i) => {
              return (
                <Fragment key={`${i}-${item.attribute}`}>
                  {item.refinements?.map((nested, j) => {
                    return (
                      <span className="chip" key={generateKey(`chip-${j}`)}>
                        <span className="category" key={item.attribute}>
                          {i18n?.t(`refinements.${item.label}`)}{' '}
                          :
                        </span>
                        <span className="filter-name">
                          {i18n?.t(
                            `refinements.${item.attribute}.${nested.label}`
                          ) || nested.label
                          }
                        </span>
                        <CloseSmallSVG
                          onClick={(event) => {
                            event.preventDefault();
                            refine(nested);
                          }}
                        />
                      </span>
                    );
                  })}
                </Fragment>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};
