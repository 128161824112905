import { StaticImage } from 'gatsby-plugin-image';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import './noResults.scss';

import GlobalContext from '../../../context/global-context';
import Lead, { leadOptions } from '../../global/lead/lead';

const NoResults: React.FC<{
  style: { display: string; marginTop?: string };
}> = ({ style }) => {
  const { i18n } = useContext(GlobalContext);

  return (
    <div className="no-results-opportunities" style={style}>
      <Row>
        <Col xs={12} lg={5} className="image-container">
          <StaticImage
            className="no-results-image"
            placeholder="blurred"
            src="../../../images/snow_field.png"
            alt="Northern spirits"
            height={400}
          />
        </Col>
        <Col xs={12} lg={7} className="info-section">
          <div className="info">
            <h4>{i18n?.t('opportunities.no_results.info.title')}</h4>
            <p>{i18n?.t('opportunities.no_results.info.body')}</p>
          </div>
          <span
            className="pseudo"
            data-text={i18n?.t('opportunities.no_results.help.tip')}
          />
          <div className="tips">
            <p>{i18n?.t('opportunities.no_results.help')}</p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default NoResults;
