import React, { Dispatch, SetStateAction, useContext } from 'react';
import { ClearRefinements } from 'react-instantsearch';

import GlobalContext from '../../../context/global-context';
import { ReactComponent as CloseSVG } from '../../../images/close_big.svg';
import { ReactComponent as OptionsSVG } from '../../../images/options.svg';
import CustomButton, {
  buttonOptions
} from '../../global/customButton/customButton';
import { CustomRefinementList } from '../refinementList/refinementList';
import './refinementDrawer.scss';

type RefinementDrawerProps = {
  toggle: Dispatch<SetStateAction<boolean>>;
  isOpened: boolean;
  filterCount: number;
};

export const RefinementDrawer : React.FC<RefinementDrawerProps> = (props) => {
  const { toggle, isOpened, filterCount } = props;
  const { i18n } = useContext(GlobalContext);

  const renderMobileMenu = () => {
    return (
      <div
        className={`mobile-panel`}
        style={isOpened ? { height: '100%' } : { height: '0%' }}
      >
        <div className="panel-content">
          <div className="title">
            <span className="option-svg">
              <OptionsSVG />
            </span>
            {i18n?.t('filters.mobile.title')}
            <span className="close-svg" onClick={() => toggle(!isOpened)}>
              <CloseSVG />
            </span>
          </div>
          <div className="filters">
            <>
              <CustomRefinementList
                title={
                  i18n?.t('refinements.status')
                    ? i18n?.t('refinements.status')
                    : 'Status'
                }
                attribute="status"
              />
              <CustomRefinementList
                title={
                  i18n?.t('refinements.industry')
                    ? i18n?.t('refinements.industry')
                    : 'Industry'
                }
                attribute="industry_tags"
                limit={100}
              />
              <CustomRefinementList
                title={
                  i18n?.t('refinements.location')
                    ? i18n?.t('refinements.location')
                    : 'Location'
                }
                attribute="locations"
                limit={50}
              />
              <CustomRefinementList
                title={
                  i18n?.t('refinements.provider')
                    ? i18n?.t('refinements.provider')
                    : 'Provider'
                }
                attribute="provider"
                limit={50}
              />
              <CustomRefinementList
                title={
                  i18n?.t('refinements.estimated_value')
                    ? i18n?.t('refinements.estimated_value')
                    : 'Estimated value'
                }
                attribute="estimated_value"
              />
              <CustomRefinementList
                title={
                  i18n?.t('refinements.publication_date')
                    ? i18n?.t('refinements.publication_date')
                    : 'Publication date'
                }
                attribute="publication_date"
              />
            </>
          </div>
          <div className="buttons">
            <div className="filter-btn">
              <CustomButton
                style={buttonOptions.filled}
                classSuffix="--small"
                onClick={() => toggle(!isOpened)}
              >
                {i18n?.t('filters.mobile.search')}
              </CustomButton>
            </div>
            {filterCount != 0 ? (
              <div className="clear-btn">
                <ClearRefinements
                  translations={{
                    resetButtonText: i18n?.t('business_registry.clear'),
                  }}
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  };
  
  return (<>{renderMobileMenu()}</>);
};