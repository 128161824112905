import axios from 'axios';
import React, { useEffect, useState } from 'react';

import Banner, { pageType } from '../components/global/banner/banner';
import Layout from '../components/global/layout/layout';
import SearchEngine from '../components/opportunities/searchEngine/searchEngine';
import { PageBase } from '../context/global-context';
import { FilterableAttributes } from '../types/filters';
import {
  OpportunitiesData,
  OpportunitiesLocalizationData,
} from '../types/opportunities';
import { PageContext } from '../types/page';
import { getLocales, LocaleEnum, useI18n } from '../utils/i18n';

type OpportunitiesContext = PageContext & {
  locale: LocaleEnum;
  slug: string;
};

const Opportunities: React.FC<{
  pageContext: OpportunitiesContext;
  location: Location;
}> = ({ pageContext, location }) => {
  const { locale, slug, translations } = pageContext;

  const defaultfilterableAttributes: FilterableAttributes = {};
  const [filterableAttributes, setFilterableAttributes] =
    useState<FilterableAttributes>(defaultfilterableAttributes);
  const [loading, setLoading] = useState(true);

  const i18n = useI18n(translations);

  const locales = getLocales([locale]);

  useEffect(() => {
    try {
      axios
        .get(
          `${process.env.GATSBY_STRAPI_URL}/api/data/filters` +
            `?locale=${locale}` +
            `&type=opportunities`
        )
        .then((response) => {
          if (response.status === 200) {
            setFilterableAttributes(response.data);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
    }
  }, []);

  const localizationsData: OpportunitiesLocalizationData[] = [];

  locales.forEach((l) => {
    const slug = i18n.t('opportunities.slug', l);

    localizationsData.push({
      attributes: {
        slug: slug ? slug : 'opportunities',
        locale: l,
      },
    });
  });

  const instance: OpportunitiesData = {
    attributes: {
      slug: slug,
      locale: locale,
      localizations: {
        data: localizationsData,
      },
    },
  };

  return (
    <PageBase
      translations={translations}
      instance={instance}
      filterableAttributes={filterableAttributes}
    >
      <Layout>
        <Banner
          pageType={pageType.page}
          bannerType="opportunity-search-page"
          title={i18n.t('opportunities.title')}
        />
        <SearchEngine
          location={location}
          filtersLoading={loading}
          filterableAttributes={filterableAttributes}
        />
      </Layout>
    </PageBase>
  );
};

export default Opportunities;
