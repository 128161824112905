import React, { useContext, useState } from "react";
import Modal from "../../global/modal/modal";
import { AlertRegistrationModal } from "./alertRegistrationModal";

import './alertRegistrationPanel.scss';
import GlobalContext from "../../../context/global-context";

type AlertRegistrationPanelProps = {
  isMobile: boolean;
  setOppSubAlert: any;
};

export const AlertRegistrationPanel : React.FC<AlertRegistrationPanelProps> = (props) => {
  const { isMobile, setOppSubAlert } = props;
  const [modal, setModal] = useState<boolean | null>(null);

  const { i18n } = useContext(GlobalContext);

  return (
    <div
      className={`search-opportunities-alert-register-wrapper ${
        isMobile ? 'mobile' : 'desktop'
      }`}
    >
      <Modal modal={modal} setModal={setModal} publicSite={true}>
        <AlertRegistrationModal
          setModal={setModal}
          setOppSubAlert={setOppSubAlert}
        />
      </Modal>

      <h3>{i18n?.t('alertSub.cta.title')}</h3>
      <p>{i18n?.t('alertSub.cta.desc')}</p>
      <button onClick={() => setModal(true)}>
        {i18n?.t('alertSub.cta.button')}
      </button>
    </div>
  );
};