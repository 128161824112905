import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Configure, ConfigureProps, InstantSearch } from "react-instantsearch";
import { history as routerHistory } from 'instantsearch.js/es/lib/routers';

import GlobalContext from '../../../context/global-context';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { FilterableAttributes } from '../../../types/filters';
import { isBrowser } from '../../../utils/browser';
import { generateKey } from '../../../utils/keys';
import {
  createURL,
  getSearchClient,
  searchStateToUrl,
  urlToSearchState,
  statusFilters,
  publicationDateFilters
} from "../../../utils/search";
import { CustomRefinementPagination } from '../../businessRegistry/refinementPagination/refinementPagination';
import CustomToast from '../../global/customToast/customToast';
import Loading from '../../global/loading/loading';
import { AlertRegistrationPanel } from '../alertRegistration/alertRegistrationPanel';
import NoResults from '../noResults/noResults';
import { OpportunityHits } from '../opportunityHits/opportunityHits';
import { RefinementDrawer } from '../refinementDrawer/refinementDrawer';
import { RefinementPanel } from '../refinementPanel/refinementPanel';
import { CustomRefinement } from '../refinements/refinements';
import { CustomStats } from '../stats/stats';
import './searchEngine.scss';

type SearchEngineProps = {
  location: Location;
  filtersLoading: boolean;
  filterableAttributes: FilterableAttributes;
}

const SearchEngine : React.FC<SearchEngineProps> = (props) => {
  const { location, filtersLoading, filterableAttributes } = props;
  const { instance, i18n } = useContext(GlobalContext);
  const { width } = useWindowDimensions();

  const searchClient = getSearchClient();
  const indexName = `opportunities-${instance?.attributes.locale}:published_at:desc`;
  const [initialUiState, setInitialUiState] = useState<any>(
    urlToSearchState({ search: '' })
  );

  function onStateChange(params: { uiState: any, setUiState: (uiState: any) => void}) {
    if (!isBrowser) return;

    const { uiState, setUiState } = params;

    history.replaceState(uiState, '', searchStateToUrl(uiState));

    setInitialUiState(uiState);
    setUiState(uiState);
  }

  useEffect(() => {
    if (filtersLoading) return;
    
    const locationSearchState: any = urlToSearchState(location);
    
    if (locationSearchState) {
      // Keep only url search params that have filters
      if (locationSearchState?.refinementList?.status) {
        let val = locationSearchState?.refinementList?.status;
        if (typeof val === 'string') val = [val];

        locationSearchState.refinementList.status = val.filter(
          (filter: string) => {
            return statusFilters.find((facet) => facet.name === filter);
          }
        );
      }
      if (locationSearchState?.refinementList?.industry_tags) {
        let val = locationSearchState?.refinementList?.industry_tags;
        if (typeof val === 'string') val = [val];

        locationSearchState.refinementList.industry_tags = val.filter(
          (filter: string) => {
            return filterableAttributes.naicsCategories?.find(
              (facet) => facet.name === filter
            );
          }
        );
      }
      if (locationSearchState?.refinementList?.locations) {
        let val = locationSearchState?.refinementList?.locations;
        if (typeof val === 'string') val = [val];

        locationSearchState.refinementList.locations = val.filter(
          (filter: string) => {
            return filterableAttributes.communities?.find(
              (facet) => facet.name === filter
            );
          }
        );
      }
      if (locationSearchState?.refinementList?.provider) {
        let val = locationSearchState?.refinementList?.provider;
        if (typeof val === 'string') val = [val];

        locationSearchState.refinementList.provider = val.filter(
          (filter: string) => {
            return filterableAttributes.providers?.find(
              (facet) => facet.name === filter
            );
          }
        );
      }
      if (locationSearchState?.refinementList?.estimated_value) {
        let val = locationSearchState?.refinementList?.estimated_value;
        if (typeof val === 'string') val = [val];

        locationSearchState.refinementList.estimated_value = val.filter(
          (filter: string) => {
            return filterableAttributes.estimatedValues?.find(
              (facet) => facet.name === filter
            );
          }
        );
      }
      if (locationSearchState?.refinementList?.publication_date) {
        let val = locationSearchState?.refinementList?.publication_date;
        if (typeof val === 'string') val = [val];

        locationSearchState.refinementList.publication_date = val.filter(
          (filter: string) => {
            return publicationDateFilters.find(
              (facet) => facet.name === filter
            );
          }
        );
      }

      setInitialUiState(locationSearchState);

      // No search add default filter for publicationDate < 12months old
      if (!locationSearchState?.refinementList)
        setInitialUiState((prevValue: any): any => {
          return {
            ...prevValue,
            refinementList: {
              ...prevValue.refinementList,
              publication_date: '12_months',
            },
          };
        });
    }
  }, [location, filtersLoading, filterableAttributes])

  const [isOpened, toggleFiltersMenu] = useState(false);
  const [isSearchResults, setIsSearchResults] = useState(false);
  const [isInitSearch, setIsInitSearch] = useState(true);
  const [filterCount, setFilterCount] = useState(0);
  const [searchResults, setSearchResults] = useState(0);
  const hitsPerPage = 10;
  const configureProps = { hitsPerPage } as ConfigureProps;

  const [oppSubAlert, setOppSubAlert] = useState<boolean | null>(null);

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={indexName}
      initialUiState={initialUiState}
      onStateChange={onStateChange}
      routing={{
        router: routerHistory({
          createURL: ({ routeState }) => createURL(routeState)
        })
      }}
    >
      <CustomRefinement
        toggle={toggleFiltersMenu}
        isOpened={isOpened}
        setFilterCount={setFilterCount}
      />
      
      <div className="search-body">
        <Row className="gx-5">
          <Col xs={0} lg={3}>
            <div className="refinement-panel-wrapper">
              {width >= 992 && <RefinementPanel />}
            </div>
            <AlertRegistrationPanel
              isMobile={false}
              setOppSubAlert={setOppSubAlert}
            />
          </Col>
          <Col lg={9}>
            <Row className="stats">
              {isInitSearch && <Loading size="medium" />}
              <Col xs={4} lg={4} className="hit-counter">
                <CustomStats
                  isInitSearch={isInitSearch}
                  setIsInitSearch={setIsInitSearch}
                  setIsSearchResults={setIsSearchResults}
                  setSearchResults={setSearchResults}
                />
              </Col>
              {!isInitSearch &&
                (isSearchResults ? (
                  <>
                    <OpportunityHits />
                    <Configure { ...configureProps } />
                    <CustomRefinementPagination
                      id={generateKey('business-refinement-pagination')}
                      hitsPerPage={hitsPerPage}
                    />
                  </>
                ) : (
                  <NoResults style={{ display: 'block', marginTop: '20px' }} />
                ))}
            </Row>
            <AlertRegistrationPanel
              isMobile={true}
              setOppSubAlert={setOppSubAlert}
            />
          </Col>
        </Row>
      </div>

      <CustomToast toast={oppSubAlert} setToast={setOppSubAlert}>
        <span className="bold">{i18n?.t('alertSub.allDone')}</span>
        <span>{i18n?.t('alertSub.sub_confirmation')}</span>
      </CustomToast>

      {width < 992 && (
        <RefinementDrawer
          toggle={toggleFiltersMenu}
          isOpened={isOpened}
          filterCount={filterCount}
        />
      )}
    </InstantSearch>
  )
};

export default SearchEngine;