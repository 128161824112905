import React, { useContext, useRef, useState } from 'react';
import { useRefinementList } from 'react-instantsearch';

import GlobalContext from '../../../context/global-context';
import { useLocaleFromRouter } from '../../../hooks/useLocaleFromRouter';
import { ReactComponent as CaretSVG } from '../../../images/caret_down.svg';
import {
  formatAlphaOrderedLabels,
  formatIndustryLevelLabels,
  sortByLabels,
  sortByOrder
} from '../../../utils/formatObject';
import { removeAccents } from '../../../utils/formatStrings';
import { uniqBy } from '../../../utils/general';
import { LocaleType } from '../../../utils/i18n';
import { generateKey } from '../../../utils/keys';
import { getInitialFacets } from '../../../utils/search';

import './refinementList.scss';

type CustomRefinementListProps = {
  title: string;
  attribute: string;
  limit?: number;
};

export const CustomRefinementList : React.FC<CustomRefinementListProps> = (props) => {
  const { attribute, limit, title } = props;
  const { items, refine } = useRefinementList({ attribute, limit });
  const { i18n, filterableAttributes } = useContext(GlobalContext);
  const currentLocale: LocaleType = useLocaleFromRouter();

  if (typeof filterableAttributes === 'undefined') return null;

  const initialFacets = getInitialFacets(
    attribute,
    filterableAttributes,
    currentLocale
  );

  let combinedItems = uniqBy([...items, ...initialFacets], 'label').map(
    (item: any) => {
      let order;
      // initialFacets have order
      if (['status', 'publication_date', 'estimated_value'].includes(attribute)) {
        const initialFacet = initialFacets.find(
          (facet) => facet.label === item.label
        );
        order = initialFacet ? initialFacet.order : undefined;
      }

      const compareLabel = removeAccents(item.label).toLowerCase();

      return {
        ...item,
        compareLabel,
        order
      };
    }
  );

  const [isOpened, useIsOpened] = useState(false);

  if (['status', 'publication_date', 'estimated_value'].includes(attribute))
    combinedItems = sortByOrder(combinedItems);

  const sortByAlpha = attribute === 'provider';

  const alphaSortedItems = sortByAlpha && formatAlphaOrderedLabels(combinedItems);

  if (attribute === 'industry_tags')
    combinedItems = formatIndustryLevelLabels(combinedItems);

  if (attribute === 'industry_tags' || attribute === 'locations')
    combinedItems = sortByLabels(combinedItems);

  // Filter search
  const filterWithSearch = ['industry_tags', 'locations'].includes(attribute);
  const searchRef = useRef<null | HTMLInputElement>(null);
  const [searchValue, setSearchValue] = useState('');
  if (filterWithSearch) {
    const searchValueCompare = searchValue.trim();
    if (searchValueCompare) {
      if (sortByAlpha) {
        Object.keys(alphaSortedItems).forEach((letter, index) => {
          alphaSortedItems[letter] = alphaSortedItems[letter].filter(
            (item: any) => item.compareLabel.includes(
              removeAccents(searchValueCompare).toLowerCase()
            )
          );
          // Remove letter if contains no filters
          if (!alphaSortedItems[letter].length) delete alphaSortedItems[letter];
        });
      } else {
        combinedItems = combinedItems.filter((item: any) =>
          item.compareLabel.includes(
            removeAccents(searchValueCompare).toLowerCase()
          )
        );
      }
    }
  }

  const listRef = useRef<null | HTMLDivElement>(null);
  const currentRefinement = combinedItems.filter((item: any) => item.isRefined);

  return (
    <div className="opp-refinement-list">
      <div
        className="refinement-type"
        onClick={() => {
          const opening = !isOpened;

          /*
          Browsers are usually restrictive of automatically performing actions for the user.
          Focus the input inside the actual onClick event instead of doing it inside a useEffect.

          Can't use "useEffect" on "isOpened" then focus the search input because iOS won't open the keyboard.
          For iOS to open the keyboard on focus it have to be on a user triggered event like "onClick".
          Display/hide the list directly on "onClick" with the updating value of "isOpened" to have the input display and focus (useState have a delay to update the value).
          */
          if (filterWithSearch) {
            if (listRef.current) {
              listRef.current.style.display = opening ? 'block' : 'none';
            }
            // Focus search if opening
            if (opening) {
              searchRef.current?.focus();
              // Reset search if closing
            } else {
              setSearchValue('');
            }
          }

          useIsOpened(opening);
        }}
      >
        {title}
        <div
          className="caret-container"
          style={currentRefinement.length !== 0 ? { width: '80px' } : {}}
        >
          <CaretSVG
            fill="#142A1E"
            style={isOpened ? { transform: 'rotate(180deg)' } : {}}
          />

          {currentRefinement.length !== 0 && (
            <span className="pseudo" data-text={currentRefinement.length} />
          )}
        </div>
      </div>
      <div
        className="filter-list"
        ref={listRef}
        style={{ display: isOpened ? 'block' : 'none' }}
      >
        {filterWithSearch && (
          <div className="filter-search">
            <input
              type="text"
              ref={searchRef}
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
          </div>
        )}

        {!sortByAlpha &&
          combinedItems &&
          combinedItems.map(
            (item: any, index: number) => {
              let label = item.label;
              if (attribute === 'status' || attribute === 'publication_date') {
                label = i18n?.t(`refinements.${attribute}.${label}`) ||
                  (label ?? attribute);
              }
              return (
                <div
                  key={generateKey(`refinement_${index}`)}
                  className={`filter-item ${item.isRefined ? 'refined' : ''}`}
                  onClick={(event) => {
                    event.preventDefault();
                    refine(item.value);
                  }}
                >
                  {label} {item.count !== 0 && `(${item.count})`}
                </div>
              );
            }
          )}
        {sortByAlpha &&
          alphaSortedItems &&
          Object.keys(alphaSortedItems).map((letter, index) => {
            return (
              <div key={`${index}-${letter}`} className="filter-heading">
                <span className="filter-heading-char">{letter}</span>
                {alphaSortedItems[letter]
                  .sort((a: any, b: any) => {
                    return a.label > b.label ? 1 : -1;
                  })
                  .map((item: any, index: number) => {
                    return (
                      <div
                        key={`${index}_${item.label}`}
                        className={`filter-item ${
                          item.isRefined ? 'refined' : ''
                        }`}
                        onClick={(event) => {
                          event.preventDefault();
                          refine(item.value);
                        }}
                      >
                        {item.label} {item.count !== 0 && `(${item.count})`}
                      </div>
                    );
                  })
                }
              </div>
            );
          })}
      </div>
    </div>
  );
};