import axios from 'axios';
import React, {
  Fragment,
  useContext,
  useState,
  Dispatch,
  SetStateAction
} from "react";
import { useCurrentRefinements } from "react-instantsearch-core";

import GlobalContext from "../../../context/global-context";
import { useLocaleFromRouter } from '../../../hooks/useLocaleFromRouter';
import { ReactComponent as ClosedWarningSVG } from '../../../images/warning_circle_orange.svg';
import { ReactComponent as WarningSVG } from '../../../images/warning_circle_red.svg';
import { LocaleType } from '../../../utils/i18n';
import { generateKey } from "../../../utils/keys";
import { EMAIL_VALIDATION } from "../../../utils/regexes";
import Loading from "../../global/loading/loading";

import {
  buildInfoString,
  isClosedSelected,
  isDisplayingFilters
} from "./alertMessageUtils";

import './alertRegistrationModal.scss';

type EmailForm = {
  value: string;
  isValid: boolean;
  isTouched: boolean;
  validator: (value: string) => boolean;
};

type AlertRegistrationModalProps = {
  setModal: Dispatch<SetStateAction<boolean | null>>;
  setOppSubAlert: Dispatch<SetStateAction<boolean>>;
};

export const AlertRegistrationModal : React.FC<AlertRegistrationModalProps> = (props) => {
  const { items } = useCurrentRefinements();
  const { setModal, setOppSubAlert } = props;

  const [emailForm, setEmailForm] = useState<EmailForm>({
    value: '',
    isValid: false,
    isTouched: false,
    validator: (value: string) => EMAIL_VALIDATION.test(value)
  });

  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const { i18n } = useContext(GlobalContext);
  const locale: LocaleType = useLocaleFromRouter();

  const handleChange = (value: string) => {
    return setEmailForm((prevVal) => {
      return {
        ...prevVal,
        value: value,
        isValid: emailForm.validator(value)
      };
    });
  };

  const handleBlur = () => {
    return setEmailForm((prevVal) => {
      return {
        ...prevVal,
        isValid: emailForm.validator(emailForm.value),
        isTouched: true
      };
    });
  };

  const createFilterObj = () => {
    return items.reduce((acc, item) => {
      if (
        item.attribute === 'publication_date' ||
        item.attribute === 'provider' ||
        (item.attribute === 'status' &&
          item.refinements.length === 1 &&
          item.refinements[0].value === 'closed'
        )
      )
        return { ...acc };
      
      let currentRefinement;
      if (item.attribute !== 'status')
        currentRefinement = item.refinements.map((r) => r.label);

      // status "closed" is an invalid fliter, remove it from the status refinements array
      if (item.attribute === 'status') {
        const tempLabels = item.refinements?.map((i) => i.label);
        currentRefinement = tempLabels?.filter((r) => r !== 'closed');
      }

      return { ...acc, [item.attribute]: currentRefinement };
    }, {});
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    // Already submitting
    if (submitting) {
      return;
    }

    if (!emailForm.isValid)
      return setEmailForm((pv) => {
        return {
          ...pv,
          isTouched: true
        };
      });
    try {
      setSubmitting(true);

      axios.post(`${process.env.GATSBY_STRAPI_URL}/api/alerts`, {
        data: {
          email: emailForm.value,
          locale,
          filters: createFilterObj()
        }
      })
      .then((response) => {
        if (response.status === 200) {
          setError(false);
          setModal(false);
          setOppSubAlert(true);
        }
      })
      .catch((err) => {
        setError(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
    } catch (err) {
      setError(true);
      setSubmitting(false);
    }
  };

  return (
    <div className="opp-alert-registration-wrapper">
      <div className="heading">
        <h1>{i18n?.t('alertSub.title')}</h1>
        <p>
          {i18n?.t('alertSub.intro')}
          {` ${buildInfoString(items, i18n)}`}
        </p>
      </div>

      {!isClosedSelected(items) && !isDisplayingFilters(items) && (
        <div className="added-margin"></div>
      )}

      {isClosedSelected(items) && (
        <div className="closed-warning-container">
          <ClosedWarningSVG />
          {i18n?.t('alertSub.status_closed_warning')}
        </div>
      )}

      {isDisplayingFilters(items) && (
        <div className="filters">
          {items.map((item, i) => {
            if (
              item.attribute === 'publication_date' ||
              item.attribute === 'provider'
            )
              return;
            return (
              <Fragment key={generateKey(`attribute-${i}`)}>
                {item.refinements?.map((nested, j) => {
                  if (
                    item.attribute === 'status' &&
                    nested.label === 'closed'
                  )
                    return;
                  return (
                    <div
                      className="filter-tag"
                      key={generateKey(`chip-${j}`)}
                    >
                      <span className="category" key={item.attribute}>
                        {i18n?.t(
                          `refinements.${item.label}`
                        )}
                        :
                      </span>
                      <span className="filter-name">
                        {i18n?.t(
                          `refinements.${item.attribute}.${nested.label}`
                        ) || nested.label}
                      </span>
                    </div>
                  );
                })}
              </Fragment>
            )
          })}
        </div>
      )}

      <form className="form" onSubmit={handleSubmit}>
        <div className="input-container form-floating">
          <input
            onChange={(e) => {
              handleChange(e.target.value);
            }}
            onBlur={handleBlur}
            className={`form-control ${
              !emailForm.isValid && emailForm.isTouched ? 'red-border' : ''
            }`}
            id="floatingInput"
            value={emailForm.value}
            type="email"
            placeholder={i18n?.t('global.emailAddress')}
          />

          {/* {SEE HERE --- NEEDS REVISION -- FLOATING EMAIL LABEL 
          <label
            htmlFor="floatingInput"
            className={`${
              !email.isValid && email.isTouched ? 'red-warning' : ''
            }`}
          >
            Email address
          </label> */}
          {!emailForm.isValid && emailForm.isTouched && (
            <span>{i18n?.t('alertSub.emailWarning')}</span>
          )}
        </div>

        <button type="submit" className="button">
          {submitting && <Loading type="btn" />}
          {!submitting && i18n?.t('global.subscribe')}
        </button>
        <button
          type="button"
          className="button decline"
          onClick={() => setModal(false)}
        >
          {i18n?.t('global.noThanks')}
        </button>
      </form>
      {error && (
        <div className="error-container">
          <div className="icon-container">
            <WarningSVG />
          </div>
          <p>{i18n?.t('alertSub.serverError')}</p>
        </div>
      )}
    </div>
  );
};