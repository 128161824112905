import moment from 'moment-timezone';
import React, { useContext } from 'react';
import { useHits } from 'react-instantsearch';

import GlobalContext from '../../../context/global-context';
import { useLocaleFromRouter } from '../../../hooks/useLocaleFromRouter';
import { ReactComponent as ClockSVG } from '../../../images/clock.svg';
import { ReactComponent as DollarSVG } from '../../../images/dollar_greenmain.svg';
import { ReactComponent as PinSVG } from '../../../images/pin.svg';
import { OpportunityHit } from '../../../types/opportunities';
import { isItNew } from '../../../utils/booleanChecks';
import { howManyDaysBetween } from '../../../utils/calcHelpers';
import {
  buildPathForDetailsPage,
  formatDateMMMDDYYYY
} from '../../../utils/formatStrings';
import { LocaleType } from '../../../utils/i18n';
import './opportunityHits.scss';

export const OpportunityHits : React.FC = () => {
  const { items } = useHits<OpportunityHit>();
  const { i18n } = useContext(GlobalContext);
  const currentLocale: LocaleType = useLocaleFromRouter();

  return (
    <div>
      {items.map((opportunity, index) => {
        const {
          deadline,
          estimated_value,
          industry_tags,
          locations,
          slug,
          provider,
          publishedAt,
          status,
          title
        } = opportunity;

        const statusTag = status?.includes('claimed')
          ? 'claimed'
          : status?.includes('open')
          ? 'open'
          : 'closed';
        const isNew = publishedAt ? isItNew(moment(publishedAt)) : false;
        const daysLeft = howManyDaysBetween(
          moment.tz(deadline + ' 23:59:59', 'America/Montreal'),
          moment().utc()
        );

        return (
          <div
            key={`${title}-${index}`}
            className="opportunity-hit-wrapper"
          >
            <a
              className="opportunity-hit"
              href={buildPathForDetailsPage(
                slug,
                'opportunity.details',
                currentLocale,
                i18n
              )}
              target={'_blank'}
            >
              <div className="top-section">
                <div className="status-update-date">
                  {isNew && statusTag === 'open' && (
                    <div className="new">{i18n?.t('status.new')}</div>
                  )}
                  <div className={`status ${statusTag}`}>
                    {i18n?.t(`status.${statusTag}`)}
                  </div>

                  {publishedAt && (
                    <span className="date">
                      {formatDateMMMDDYYYY(new Date(publishedAt))}
                    </span>
                  )}
                </div>
                <div className="title-provider">
                  <h2>{title}</h2>
                  <p>
                    {`${i18n?.t(`opportunity.details.provided_by`)} :`}{' '}
                    <span className="provider">{provider}</span>
                  </p>
                </div>
              </div>
              <div className="bottom-section">
                <div className="hit-tags">
                  {industry_tags &&
                    industry_tags.map((tag: string, index: number) => {
                      return (
                        <div key={`${tag}+${index}`} className="hit-tag">
                          <span>{tag}</span>
                        </div>
                      );
                    })}
                </div>
                <div className="bottom-info">
                  <div className="location-value">
                    <div className="location">
                      <div className="icon">
                        <PinSVG />
                      </div>
                      {locations &&
                        locations.map((area: string, index: number) => {
                          if (index > 2) return;
                          return (
                            <span key={area}>
                              {index === 0 ? '' : ', '}
                              {area}
                            </span>
                          );
                        })}
                    </div>
                    <div className="value">
                      <div className="icon">
                        <DollarSVG />
                      </div>
                      <span>
                        {`${i18n?.t(`opportunity.details.estimated_value`)}:`}{' '}
                        <span className="bold">
                          {estimated_value ?? 'N/A'}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="deadline">
                    <div className="icon">
                      <ClockSVG />
                    </div>
                    <span>
                      {`${i18n?.t(`opportunity.details.deadline`)}: `}

                      <span className="bold">
                        {deadline}{' '}
                        {daysLeft > 0 && (
                          <span className="bold">
                            {`(${daysLeft} ${i18n?.t(
                              `opportunity.details.day${
                                daysLeft > 1 ? 's' : ''
                              }_left`
                            )})`}
                          </span>
                        )}{' '}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </a>
          </div>
        );
      })}
    </div>
  );
};
