import React, { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { useStats } from 'react-instantsearch';

import GlobalContext from '../../../context/global-context';

type CustomStatsProps = {
  isInitSearch: boolean;
  setIsInitSearch: Dispatch<SetStateAction<boolean>>;
  setIsSearchResults: Dispatch<SetStateAction<boolean>>;
  setSearchResults: Dispatch<SetStateAction<number>>;
};

export const CustomStats : React.FC<CustomStatsProps> = (props) => {
  const { nbHits } = useStats();
  const { isInitSearch, setIsInitSearch, setIsSearchResults, setSearchResults } = props;

  const { i18n } = useContext(GlobalContext);

  useEffect(() => {
    setIsSearchResults(nbHits !== 0);
    setSearchResults(nbHits);
    setIsInitSearch(false);
  }, [nbHits]);

  if (isInitSearch) return null;

  return (
    <span>
      {nbHits} {i18n?.t('business_registry.results')}
    </span>
  );
};