const relevantKeys = ['locations', 'industry_tags', 'estimated_value'];

const getStatusKey = (items: any[]) => {
  // if no status was specified, return text explaining they'll receive both open and claimed items
  if (!items.some((item) => item.attribute === 'status')) return 'all';

  // also, if they selected both open and closed, return text explaining they'll get both
  const index = items.findIndex((i) => i.attribute === 'status');
  if (
    items[index].refinements.some((r: any) => r.value === 'open') &&
    items[index].refinements.some((r: any) => r.value === 'claimed')
  )
    return 'all';

  // check if they selected only one option, and return the appropriate key for the i18n object notation, fallback "all" if needed
  if (items[index].refinements.some((r: any) => r.value === 'open')) return 'open';
  if (items[index].refinements.some((r: any) => r.value === 'claimed')) return 'claimed';

  // default fallback
  return 'all';
};

export const buildInfoString = (items: any[], i18n: any) => {
  // get status filter key
  const statusKey = getStatusKey(items);

  
  // set the filter keys in a seperate array, with the status and other irrelevant filter keys removed
  const filterKeys = items.map((i) => i.attribute);
  let filterlessKeys = relevantKeys.filter((k) => !filterKeys.includes(k));


  // early return to only display intro phrase when all filters were selected
  if (filterlessKeys.length === 0) return '';


  // return default string with no restrictions to alerts if no filters and status "all" applies (all = open and granted)
  if (statusKey === 'all' && filterlessKeys.length === relevantKeys.length)
    return i18n.t('alertSub.default.noFilters');


  // if no relevant filters were chosen, set all keys to filterless
  if (filterlessKeys.length === 0) filterlessKeys = relevantKeys;


  // get filter translations array ready
  const filterStrings = filterlessKeys.map((k) =>
    i18n.t(`alertSub.filter.${k}`)
  );


  // get first section of the sentance ready with the default + status
  let phrase = `${i18n.t('alertSub.default.first')} ${i18n.t(
    `alertSub.status.${statusKey}`
  )} ${i18n.t('alertSub.default.second')} `;


  // build second section of the sentance with the filter translations
  phrase = filterStrings.reduce((acc, currentVal, index) => {
    // if it's the last element in the "filter words", add the translation and the period
    if (index + 1 === filterStrings.length) return acc + `${currentVal}.`;


    // if it's the before last element in the "filter words", add translation and conjunction particle (and/et)
    if (index + 1 === filterStrings.length - 1)
      return acc + `${currentVal} ${i18n.t('alertSub.conjunction')} `;


    // else, add the translation and the comma
    return acc + `${currentVal}, `;
  }, phrase);

  return phrase;
};


export const isClosedSelected = (items: any[])  => {  
  if (!items.some((i) => i.attribute === "status")) return false;

  // find index storing the status attribute, if closed is preset, return true
  const index = items.findIndex((i: any) => i.attribute === 'status');
  return items[index].refinements.some((r: any) => r.value === "closed") ? true : false;
}

export const isDisplayingFilters = (items: any[]) => {
  // filter out unrelated filters
  let filteredItems = items.filter((i) => i.attribute !== "publication_date" && i.attribute !== "provider");
  // if there are no filters left, or only a "closed" filter as a status attribute, return false. not rendering any filters and removing margin
  return (filteredItems.length === 1 && items[0].refinements.length === 1 && items[0].refinements[0].value === "closed") 
         || filteredItems.length === 0  ? false : true; 
}