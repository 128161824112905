import React, { useContext } from 'react';

import GlobalContext from '../../../context/global-context';
import './refinementPanel.scss';
import { CustomRefinementList } from '../refinementList/refinementList';

export const RefinementPanel : React.FC = () => {
  const { i18n } = useContext(GlobalContext);

  return (
    <div className="desktop-panel">
      <CustomRefinementList
        title={i18n?.t('refinements.status') ??
          'Status'}
        attribute="status"
      />
      <CustomRefinementList
        title={i18n?.t('refinements.industry') ??
          'Industry'}
        attribute="industry_tags"
        limit={100}
      />
      <CustomRefinementList
        title={i18n?.t('refinements.location') ??
          'Location'}
        attribute="locations"
        limit={50}
      />
      <CustomRefinementList
        title={i18n?.t('refinements.provider') ??
          'Provider'}
        attribute="provider"
        limit={50}
      />
      <CustomRefinementList
        title={i18n?.t('refinements.estimated_value') ??
          'Estimated value'}
        attribute="estimated_value"
      />
      <CustomRefinementList
        title={i18n?.t('refinements.publication_date') ??
          'Publication date'}
        attribute="publication_date"
      />
    </div>
  );
};