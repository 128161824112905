import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { useSearchBox } from "react-instantsearch";

import GlobalContext from "../../../context/global-context";
import { ReactComponent as OptionsSVG } from '../../../images/options.svg';

import './searchBox.scss';

type CustomSearchBoxProps = {
  className: string;
  toggle: Dispatch<SetStateAction<boolean>>;
  isOpened: boolean;
  counter: number | undefined;
};

export const CustomSearchBox : React.FC<CustomSearchBoxProps> = (props) => {
  const { query, refine } = useSearchBox();
  const { className, toggle, isOpened, counter } = props;
  const { i18n } = useContext(GlobalContext);
  const [searchQuery, setSearchQuery] = useState(query);

  return (
    <div className={`search-box ${className}`}>
      <form
        noValidate
        role="search"
        onSubmit={(e) => {
          e.preventDefault();
          refine(searchQuery)
        }}
      >
        <input
          placeholder={i18n?.t('business_registry.search_placeholder')}
          type="search"
          defaultValue={query}
          onChange={(event) => {
            setSearchQuery(event.currentTarget.value);
          }}
        />
        <button className="search-button" type="submit">
          {i18n?.t('business_registry.search')}
        </button>

        <div
          onClick={() => toggle(!isOpened)}
          className={`options ${counter !== 0 ? 'options-active' : ''}`}
        >
          <OptionsSVG className={`options-icon`} />
          {counter !== 0 && <span className="counter">{counter}</span>}
        </div>
      </form>
    </div>
  );
};